import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import Loader from "./../Loader";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRight,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

import { setLanguage } from "../data/languageSlice";
import { useTranslation } from "react-i18next";
import { fetchLayoutGroup } from "../data/layoutGroupSlice";
import axios from "axios";
import { baseUrl } from "../data/baseUrl";
import Loader from "../Loader";

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [viewLanguages, setViewLanguages] = useState(false);
  const { layoutGroup, loading, error } = useSelector(
    (state) => state.layoutGroup
  );
  useEffect(() => {
    dispatch(fetchLayoutGroup(language));
  }, [dispatch, language]);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const heightToShow = window.innerHeight / 5;
    if (window.scrollY > heightToShow) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*____________________________News Letter____________________________________ */

  // const [formData, setFormData] = useState({
  //   emailAddress: "",
  //   namehoney: "",
  //   emailhoney: "",
  // });
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOperation, setErrorOperation] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const requestData = {
      txt_CustomerEmail: email,
    };
    try {
      await axios.post(
        "https://api.dharahotels.com/api/WSRequests/api/requests/requestNewsletter",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );
      setSuccessOperation(true);
    } catch (error) {
      setErrorMessage(error.message);
      setErrorOperation(true);
      setSuccessOperation(false);
    } finally {
      setIsSubmitting(false);
      setEmail("");
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSuccessOperation(false);
    setErrorOperation(false);
  };
  /*____________________________Languages____________________________________ */
  const customLanguages = layoutGroup.groupHeader?.languages
    ?.map((lang) => lang)
    .filter(
      (abbrev) =>
        abbrev.languageAbbreviation != "dd" &&
        abbrev.languageAbbreviation != language
    );
  const selectedLanguages = layoutGroup.groupHeader?.languages
    ?.map((lang) => lang)
    .find((abbrev) => abbrev.languageAbbreviation == language);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  useEffect(() => {
    // Add or remove the class on the body element based on the activeMenu state
    if (activeMenu) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }

    // Clean up function to remove the class when the component unmounts or before the effect runs again
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [activeMenu]);
  const toggleLanguage = (selectedLanguage) => {
    const newLanguage = selectedLanguage.languageAbbreviation;
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {/*Header*/}

          <div className="page-wrapper">
            {" "}
            <header className="main-header header-style-one alternate">
              <div className="header-upper">
                <div className="inner-container clearfix">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to="/" title="">
                        <img
                          src={layoutGroup.groupHeader?.groupLogo}
                          alt="BenBen Hotel - Aswan"
                          title={t("BenBen Hotel")}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="nav-outer clearfix">
                    <div
                      className="mobile-nav-toggler"
                      onClick={() => setActiveMenu(!activeMenu)}
                    >
                      <span className="icon flaticon-menu-2"></span>
                      <span className="txt">{t("Menu")}</span>
                    </div>

                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div
                        className="collapse navbar-collapse show clearfix"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation clearfix">
                          <li className="current">
                            <Link to="/">{t("Home")}</Link>{" "}
                          </li>
                          <li>
                            <Link to="/accommodation">
                              {t("Accommodation")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/dining">{t("Dining")}</Link>
                          </li>
                          <li>
                            <Link to="/activities">{t("Activities")}</Link>
                          </li>
                          {layoutGroup.groupHeader?.isEnabledOffers && (
                            <li>
                              <Link to="/offers">{t("Offers")}</Link>
                            </li>
                          )}
                          {layoutGroup.groupHeader?.isEnabledFaq && (
                            <li>
                              <Link to="/faq">{t("Faq")}</Link>
                            </li>
                          )}
                          <li>
                            <Link to="/gallery">{t("Gallery")}</Link>
                          </li>

                          <li className="dropdown">
                            <Link to="#." onClick={() => setView(!view)}>
                              {t("About Us")}
                            </Link>
                            <ul style={{ display: view ? "block" : "none" }}>
                              <li>
                                <Link to="/dhara-hotels">
                                  {" "}
                                  {t("Dhara Hotels")}
                                </Link>
                              </li>
                              <li>
                                <Link to="/about-us">{t("BenBen Hotel")}</Link>
                              </li>
                              <li>
                                <Link to="/story"> {t("BenBen's Story")}</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/contact-us">{t("Contact")}</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>

                  <div className="other-links   d-flex align-items-center">
                    {" "}
                    <nav className="main-menu  mr-3">
                      <ul className="navigation  ">
                        <li
                          className="dropdown py-0"
                          onClick={() => setViewLanguages(!viewLanguages)}
                        >
                          <Link
                            to="#."
                            className="py-0  "
                            style={{
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faGlobeAsia}
                              className="mr-1"
                            />{" "}
                            {selectedLanguages.languageName ?? "English"}{" "}
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="ml-1"
                              style={{ fontSize: "10px" }}
                            />
                          </Link>
                          <ul
                            className="languages"
                            style={{
                              display: viewLanguages ? "block" : "none",
                              width: "auto",
                            }}
                          >
                            {customLanguages?.map((lang) => (
                              <li
                                onClick={() => toggleLanguage(lang)}
                                key={lang.languageAbbreviation}
                                className="d-flex align-items-center pl-3 pr-5"
                              >
                                <img
                                  alt={lang.languageAbbreviation}
                                  src={lang.languageFlag}
                                  // src={
                                  //   "https://cms.hwaidakhotels.com/images/flags/ru.jpg"
                                  // }
                                  className="mr-2"
                                />{" "}
                                <Link
                                  to="#."
                                  onClick={() => setActiveMenu(false)}
                                >
                                  {lang.languageName}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                    <div className="info pt-lg-0">
                      <ul className="clearfix">
                        <li>
                          <Link
                            to={`tel:${layoutGroup.groupHeader?.groupPhone}`}
                          >
                            <span className="icon flaticon-smartphone-2"></span>
                            <span className="txt">
                              {layoutGroup.groupHeader?.groupPhone}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="link-box">
                      <a
                        href="https://www.book-secure.com/index.php?s=results&property=egasw00001&currency=USD"
                        target="_blank"
                        className="theme-btn btn-style-two"
                      >
                        <span className="btn-title">{t("Book Your Stay")}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-menu">
                <div
                  className="close-btn"
                  onClick={() => setActiveMenu(!activeMenu)}
                >
                  <span className="icon flaticon-targeting-cross"></span>
                </div>
                <div
                  className="menu-backdrop"
                  onClick={() => setActiveMenu(!activeMenu)}
                ></div>
                <div className="nav-logo">
                  <Link to="/">
                    <img
                      src={layoutGroup.groupHeader?.groupLogo}
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
                <nav class="menu-box mCustomScrollbar _mCS_1">
                  <div
                    id="mCSB_1"
                    class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                    tabindex="0"
                    style={{ maxHeight: "100%", overflow: "auto" }}
                  >
                    <div
                      id="mCSB_1_container"
                      class="mCSB_container"
                      style={{ position: "relative", top: 0, left: 0 }}
                      dir="ltr"
                    >
                      <div class="menu-outer">
                        <div
                          class="collapse navbar-collapse show clearfix"
                          id="navbarSupportedContent"
                        >
                          <ul class="navigation clearfix">
                            <li class="current">
                              <Link to="/">{t("Home")}</Link>{" "}
                            </li>
                            <li>
                              <Link to="/accommodation">
                                {t("Accommodation")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/dining">{t("Dining")}</Link>
                            </li>
                            <li>
                              <Link to="/activities">{t("Activities")}</Link>
                            </li>
                            {layoutGroup.groupHeader?.isEnabledOffers && (
                              <li>
                                <Link to="/offers">{t("Offers")}</Link>
                              </li>
                            )}
                            {layoutGroup.groupHeader?.isEnabledFaq && (
                              <li>
                                <Link to="/faq">{t("Faq")} </Link>
                              </li>
                            )}
                            <li>
                              <Link to="/gallery">{t("Gallery")}</Link>
                            </li>

                            <li class="dropdown">
                              <Link to="#." onClick={() => setView(!view)}>
                                {t("About Us")}{" "}
                                {view ? (
                                  <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className="ml-2"
                                    style={{ fontSize: "10px" }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                    className="ml-2"
                                    style={{ fontSize: "10px" }}
                                  />
                                )}
                              </Link>
                              <ul
                                style={{
                                  display: view ? "block" : "none",
                                  color: "#fff",
                                }}
                              >
                                <li>
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="ml-2"
                                    style={{ fontSize: "10px" }}
                                  />{" "}
                                  <Link to="/dhara-hotels">
                                    {" "}
                                    {t("Dhara Hotels")}
                                  </Link>
                                </li>
                                <li>
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="ml-2"
                                    style={{ fontSize: "10px" }}
                                  />{" "}
                                  <Link to="/about-us">
                                    {" "}
                                    {t("BenBen Hotel")}
                                  </Link>
                                </li>
                                <li>
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="ml-2"
                                    style={{ fontSize: "10px" }}
                                  />{" "}
                                  <Link to="/story">
                                    {" "}
                                    {t("BenBen's Story")}
                                  </Link>
                                </li>
                              </ul>
                              <div class="dropdown-btn">
                                <span class="fa fa-angle-right"></span>
                              </div>
                            </li>
                            <li>
                              <Link to="/contact-us">{t("Contact")}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="nav-bottom">
                  <div className="copyright">
                    {layoutGroup.groupFooter?.copyrights}
                  </div>

                  <div className="social-links">
                    <ul className="clearfix">
                      {layoutGroup.groupFooter?.groupSocial?.map(
                        (social, index) => (
                          <li key={index}>
                            <Link
                              to={social.socialUrl}
                              title={social.socialName}
                              target="_blank"
                            >
                              <span className={social.socialClass}></span>
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </header>
            {/* <Link
          to="#rlrTransNav"
          className={`back-to-top ${isVisible ? "show" : ""}`}
          onClick={scrollToTop}
        >
          <i className="fa fa-angle-up"></i>
        </Link> */}
            <div
              class="scroll-to-top scroll-to-target"
              onClick={scrollToTop}
              data-target="html"
            >
              <span class="flaticon-up-arrow"></span>
            </div>
            {children}
            <footer className="main-footer">
              <div className="top-pattern-layer-dark"></div>

              <div className="widgets-section">
                <div className="auto-container">
                  <div className="row clearfix">
                    <div className="column col-xl-3 col-lg-12 col-md-12 col-sm-12">
                      <div className="footer-widget about-widget">
                        <div className="logo">
                          <Link to="/">
                            <img
                              src={layoutGroup.groupFooter?.groupLogo}
                              alt={t("BenBen Hotel")}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="column col-xl-5 col-lg-8 col-md-12 col-sm-12">
                      <div className="footer-widget links-widget">
                        <div className="widget-content">
                          <div className="row clearfix">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="widget-title">
                                <h4>{t("About Us")}</h4>
                              </div>
                              <ul className="links">
                                <li>
                                  <Link to="/accommodation">
                                    {t("Accommodation")}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dining">{t("Dining")}</Link>
                                </li>
                                <li>
                                  <Link to="/activities">
                                    {t("Activities")}
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/gallery">{t("Gallery")}</Link>
                                </li>

                                <li>
                                  <Link to="/about-us">{t("About Us")}</Link>
                                </li>
                                <li>
                                  <Link to="/contact-us">
                                    {t("Contact Us")}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="widget-title">
                                <h4>{t("Contacts")}</h4>
                              </div>
                              <ul className="info">
                                <li className="address">
                                  <Link
                                    to={
                                      layoutGroup.groupFooter
                                        ?.groupGetDeirectionLink
                                    }
                                  >
                                    {" "}
                                    {layoutGroup.groupFooter?.groupAddress}{" "}
                                  </Link>
                                </li>
                                <li className="phone">
                                  <Link
                                    to={`tel:${layoutGroup.groupFooter?.groupPhone}`}
                                  >
                                    {layoutGroup.groupFooter?.groupPhone}
                                  </Link>
                                </li>
                                <li className="email">
                                  <Link
                                    to={`mailto:${layoutGroup.groupFooter?.groupMail}`}
                                  >
                                    {layoutGroup.groupFooter?.groupMail}
                                  </Link>
                                </li>
                                <li className="social-links">
                                  {layoutGroup.groupFooter?.groupSocials?.map(
                                    (social, index) => (
                                      <Link
                                        key={index}
                                        to={social.socialUrl}
                                        title={social.socialName}
                                        target="_blank"
                                      >
                                        <span
                                          className={social.socialClass}
                                        ></span>
                                      </Link>
                                    )
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="column col-xl-4 col-lg-4 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <div className="widget-title">
                          <h4>{t("Newsletter")}</h4>
                        </div>
                        <div className="text">
                          {t(
                            "Receive exclusive offers & discounts for your trips by subscribing to the BenBen Hotel Newsletter now."
                          )}
                        </div>

                        <div className="newsletter-form">
                          <form id="news" onSubmit={handleSubmit}>
                            <div className="form-group clearfix">
                              <input
                                type="email"
                                className="  py-2"
                                name="email"
                                value={email}
                                onChange={handleChange}
                                placeholder={t("Enter your email address")}
                                required
                              />
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                                disabled={isSubmitting}
                              >
                                <span className="btn-title">
                                  {" "}
                                  {isSubmitting
                                    ? t("Loading...")
                                    : t("Send Now")}
                                </span>
                              </button>
                            </div>
                          </form>{" "}
                          {errorOperation ? (
                            <>
                              <Snackbar
                                open={errorOperation}
                                autoHideDuration={6000}
                                onClose={handleClose}
                              >
                                <Alert
                                  onClose={handleClose}
                                  severity="error"
                                  variant="filled"
                                  sx={{ width: "100%" }}
                                >
                                  {errorMessage}
                                </Alert>
                              </Snackbar>
                            </>
                          ) : (
                            <Snackbar
                              open={successOperation}
                              autoHideDuration={6000}
                              onClose={handleClose}
                            >
                              <Alert
                                onClose={handleClose}
                                severity="success"
                                variant="filled"
                                sx={{ width: "100%" }}
                              >
                                {t("You Are Subscribed Now. Thank You!")}
                              </Alert>
                            </Snackbar>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-bottom">
                <div className="auto-container">
                  <div className="inner clearfix">
                    <div className="copyright">
                      {layoutGroup.groupFooter?.copyrights}
                      <br />
                      <span style={{ fontSize: "13px" }}>
                        <Link to="https://titegypt.com" target="_blank">
                          {t("Powered by T.I.T. Solutions")}{" "}
                        </Link>
                      </span>
                    </div>
                    <div className="bottom-links">
                      {/* <Link to="/covid-19">{t("Covid 19")}</Link> &ensp;|&ensp; */}
                      <Link to="/privacy-policy">{t("Privacy Policy")}</Link>{" "}
                      &ensp;|&ensp;{" "}
                      <Link to="/terms-conditions">
                        {t("Terms & Conditions")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
};
export default Layout;
